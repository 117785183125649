import React from "react";
import { makeStyles } from "@material-ui/core";
import { useIsMobile, useIsLarge } from "../hooks";
import { VideoSelect, Schedule } from ".";
import logo from "../assets/TA-Logo-white.png";
import mobileLogo from "../assets/TA-Square-Logo.png";
import longLogo from "../assets/TAT-Wide-Logo-White.png";
import bitbean_ta from "../assets/bitbean_ta.png";

function _TopBar({
  events,
  setActiveEvent,
  activeEvent,
  eventsOrder,
  settings,
  schedWindowOpen,
  setSchedWindowOpen,
}) {
  const isMobile = useIsMobile();
  const isLarge = useIsLarge();

  const useStyles = makeStyles((theme) => ({
    topBar: {
      backgroundColor: settings.pallet ? settings.pallet.topBar : "#121212",
      height: 80,
      width: "100%",
      borderRadius: "2px 2px 0 0",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  }));
  const classes = useStyles();

  return (
    <div id="iframe-header-banner" className={classes.topBar}>
      <div id="ta-logo">
        <a
          href={settings.logoUrlLink || "https://www.torahanytime.com"}
          rel="noopener noreferrer"
          target="_blank"
        >
          {!!settings.logoUrl ? (
            <img
              style={{
                padding: 10,
                width: isMobile
                  ? settings.logoWidth.sm
                  : isLarge
                  ? settings.logoWidth.lg
                  : settings.logoWidth.md,
              }}
              alt="logo"
              src={settings.logoUrl}
            />
          ) : (
            <img
              alt="torahanytime-logo"
              style={{
                width: isMobile ? 64 : isLarge ? 300 : 110,
              }}
              src={isMobile ? mobileLogo : isLarge ? longLogo : logo}
            />
          )}
        </a>
      </div>
      <div id="campaign-logo">
        {!!settings.campaignLogo && (
          <a
            href={settings.campaignLogoLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              style={{
                padding: 10,
                width: isMobile
                  ? settings.campaignLogoWidth.sm
                  : isLarge
                  ? settings.campaignLogoWidth.lg
                  : settings.campaignLogoWidth.md,
              }}
              alt="campaignLogo"
              src={settings.campaignLogo}
            />
          </a>
        )}
      </div>
      {/* {!!isLarge && <span>Classes</span>} */}
      {settings.hideSelect !== true ? (
        <VideoSelect
          settings={settings}
          events={events}
          videoSelect={setActiveEvent}
          activeEvent={activeEvent}
          eventsOrder={eventsOrder}
        />
      ) : null}
      {!!settings.schedule_url && (
        <Schedule
          settings={settings}
          schedWindowOpen={schedWindowOpen}
          setSchedWindowOpen={setSchedWindowOpen}
        />
      )}
      {settings.showBitbeanTaTop === true && (
        <div>
          <img
            style={{
              paddingRight: 10,
              width: isLarge ? 110 : 85,
            }}
            src={bitbean_ta}
            alt="TorahAnytime-Bitbean-player"
          />
        </div>
      )}
    </div>
  );
}

export const TopBar = React.memo(_TopBar);
