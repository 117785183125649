import React from "react";
import { Select, MenuItem, makeStyles } from "@material-ui/core";
import { useIsMobile, useIsLarge } from "../hooks";



function _VideoSelect({ events, videoSelect, activeEvent, eventsOrder,settings }) {
  const useStyles = makeStyles((theme) => ({
    dropdownIcon: {
      color: settings.videoSelect?.color || "#fff",
    },
    menu: {
      backgroundColor: settings.videoSelect?.backgroundColor || "#2E2E2E",
      color: settings.videoSelect?.color || "#fff",
    },
  }));

  const classes = useStyles();
  const isMobile = useIsMobile();
  const isLarge = useIsLarge();
  const {
    videoSelect:{
      width,
      color
    } = {},
  } = settings;
  return (
    <Select
      classes={{ icon: classes.dropdownIcon }}
      className="video-select"
      style={{
        color: color || "#fff",
        width: width ? isLarge ? width.lg : isMobile ? width.sm : width.md : isMobile ? 180 : isLarge ? 400 : 260,
        borderRadius: 2,
        backgroundColor: settings.videoSelect?.backgroundColor || "#2E2E2E",
        padding: 8,
        marginRight: isLarge ? 40 : 10,
        fontSize: settings.videoSelect?.fontSize || "inherit"
      }}
      value={activeEvent.code}
      MenuProps={{
        classes: { paper: classes.menu },
        // anchorOrigin: {
        //   vertical: "top",
        // },
        // transformOrigin: {
        //   vertical: "top",
        // },
        // getContentAnchorEl: null,
      }}
      onChange={(e) => videoSelect(events[e.target.value])}
    >
      {eventsOrder.map((k) => (
        <MenuItem key={k} value={k} style={{
          fontSize: settings.videoSelect?.menuItemFontSize || "inherit"
        }} >
          {events[k]?.title}
        </MenuItem>
      ))}
    </Select>
  );
}

export const VideoSelect = React.memo(_VideoSelect);
