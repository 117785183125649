import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  makeStyles,
  Link,
  Dialog,
  DialogActions,
} from "@material-ui/core";
import { useIsMobile, useIsLarge } from "../hooks";
import bitbean_ta from "../assets/bitbean_ta.png";

function _BottomBar({ settings }) {
  const [open, setOpen] = useState(false);

  const {
    bottom_link_1,
    bottom_link_2,
    donationBtnTextLine1,
    donationBtnTextLine2,
    middleSectionStyle,
    linkStyles = {},
  } = settings;

  const handleClose = () => {
    setOpen(false);
  };
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const isMobile = useIsMobile();
  const isLarge = useIsLarge();
  let btnLine1Style = donationBtnTextLine1?.style || {};
  if (btnLine1Style.lg && isLarge) {
    btnLine1Style = { ...btnLine1Style, ...btnLine1Style.lg };
  } else if (btnLine1Style.sm && isMobile) {
    btnLine1Style = { ...btnLine1Style, ...btnLine1Style.sm };
  } else if (btnLine1Style.md) {
    btnLine1Style = { ...btnLine1Style, ...btnLine1Style.md };
  }

  delete btnLine1Style.lg;
  delete btnLine1Style.md;
  delete btnLine1Style.sm;

  let btnLine2Style = donationBtnTextLine2?.style || {};
  if (btnLine2Style.lg && isLarge) {
    btnLine2Style = { ...btnLine2Style, ...btnLine2Style.lg };
  } else if (btnLine2Style.sm && isMobile) {
    btnLine2Style = { ...btnLine2Style, ...btnLine2Style.sm };
  } else if (btnLine2Style.md) {
    btnLine2Style = { ...btnLine2Style, ...btnLine2Style.md };
  }

  delete btnLine2Style.lg;
  delete btnLine2Style.md;
  delete btnLine2Style.sm;

  let middleSectionStyles = middleSectionStyle || {};
  if (middleSectionStyles.lg && isLarge) {
    middleSectionStyles = { ...middleSectionStyles, ...middleSectionStyles.lg };
  } else if (middleSectionStyles.sm && isMobile) {
    middleSectionStyles = { ...middleSectionStyles, ...middleSectionStyles.sm };
  } else if (middleSectionStyles.md) {
    middleSectionStyles = { ...middleSectionStyles, ...middleSectionStyles.md };
  }

  delete middleSectionStyles.lg;
  delete middleSectionStyles.md;
  delete middleSectionStyles.sm;

  const useStyles = makeStyles((theme) => ({
    linkStyles: {
      color: "rgb(255 189 105)",
      textDecoration: "underline",
      fontSize: isLarge ? 24 : isMobile ? 13 : 16,
      marginRight: 20,
      marginBottom: isMobile ? 4 : 0,
      ...linkStyles,
    },
    leftBottomLarge: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      width: "85%",
    },
    leftBottomRegular: {
      display: "flex",
      flexDirection: isLarge ? "row" : "column",
    },
    bottomBar: {
      height: 88,
      width: "100%",
      borderRadius: "0 0 2px 2px",
      backgroundColor: settings.pallet?.bottomBar || "#1475b7",
      position: "relative",
      zIndex: 100,
    },
    ...settings.bottomBarStyles,
    middleSection: {
      ...middleSectionStyles,
    },
    bottonTxtLine1: {
      padding: !isMobile ? "12px 44px" : 12,
      borderRadius: 2,
      backgroundColor: settings?.pallet?.donateBtn || "#F37301",
      color: "#FFFFFF",
      fontSize: isMobile ? 14 : isLarge ? 24 : 20,
      fontWeight: 500,
      textAlign: "center",
      ...btnLine1Style,
    },
    bottonTxtLine2: {
      ...btnLine2Style,
    },
  }));
  const classes = useStyles();

  return (
    <div
      id="iframe-bottom-bar"
      className={classes.bottomBar}
      style={{
        padding: isMobile ? 8 : isLarge ? 20 : 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...(settings?.hideDonateButton ? { justifyContent: "center" } : {}),
      }}
    >
      <div
        className={
          isLarge && !settings?.hideDonateButton
            ? classes.leftBottomLarge
            : classes.leftBottomRegular
        }
      >
        {settings.showBitbeanTaBottom === true && (
          <div>
            <img
              style={{
                paddingRight: 8,
                width: isLarge ? 130 : 85,
                ...(settings?.hideDonateButton ? { margin: "auto" } : {}),
              }}
              src={bitbean_ta}
              alt="TorahAnytime-Bitbean-player"
            />
          </div>
        )}
        {!!settings.bottomBarSponsor && (
          <div>
            <img
              style={{
                paddingRight: !settings?.hideDonateButton ? 10 : 0,
                width: isLarge
                  ? settings.bottomBarSponsor.lg
                  : isMobile
                  ? settings.bottomBarSponsor.sm
                  : settings.bottomBarSponsor.md,
              }}
              src={settings.bottomBarSponsor.src}
              alt="campaign-sponsors"
            />
          </div>
        )}
        <div className={classes.middleSection}>
          <div
            style={
              settings.bottom_text_style
                ? isLarge
                  ? settings.bottom_text_style.lg
                  : isMobile
                  ? settings.bottom_text_style.sm
                  : settings.bottom_text_style.md
                : {
                    color: "#FFFFFF",
                    fontSize: isMobile ? 16 : isLarge ? 40 : 20,
                    fontWeight: 500,
                  }
            }
          >
            {settings.bottom_text}
          </div>
          <div
            className="bottom-links-wrapper"
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            {bottom_link_1 && (
              <Link
                rel="noreferrer noopener"
                target={
                  bottom_link_1.behaviour === "new_tab" ? "_blank" : "_self"
                }
                className={classes.linkStyles}
                href={bottom_link_1.url}
              >
                {bottom_link_1.label}
              </Link>
            )}
            {bottom_link_2 && (
              <Link
                rel="noreferrer noopener"
                target={
                  bottom_link_2.behaviour === "new_tab" ? "_blank" : "_self"
                }
                className={classes.linkStyles}
                href={bottom_link_2.url}
              >
                {bottom_link_2.label}
              </Link>
            )}
          </div>
        </div>
      </div>

      <div>
        {Object.keys(settings).length > 1 && !settings?.hideDonateButton && (
          <Button
            onClick={() =>
              !!settings.donationNewTab
                ? window.open(settings.donationNewTab, "_blank")
                : setOpen(true)
            }
          >
            <span className={classes.bottonTxtLine1}>
              {donationBtnTextLine1?.txt || "Donate"}
              {!!donationBtnTextLine2 && (
                <span className={classes.bottonTxtLine2}>
                  {donationBtnTextLine2.txt}
                </span>
              )}
            </span>
          </Button>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          //fullWidth
          fullScreen
        >
          <iframe
            title="donate"
            src={
              settings.donationIframe ||
              "https://beta.torahanytime.com/donate-embed"
            }
            //src="http://localhost:3001/donate-embed"
            frameBorder="0"
            allowFullScreen
            style={{ height: "100vh", width: "100%" }}
          />
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export const BottomBar = React.memo(_BottomBar);
