module.exports = {
  tbav: [
    {
      title: "Project Inspire",
      link: "https://livestream.com/accounts/20631388/events/9112248/player",
      code: "project_inspire",
    },
    {
      title: "Chazaq",
      link: "https://www.youtube.com/embed/yyUFJMm3hv8",
      code: "chazaq",
    },
    {
      title: "Aish",
      link: "https://www.torahanytime.com/embed/?v=118850",
      code: "aish",
    },
  ],
  men: [
    {
      title: "Project Inspire Men",
      link: "https://livestream.com/accounts/20631388/events/9112248/player",
      code: "project_inspire",
    },
    {
      title: "Chazaq Men",
      link: "https://www.youtube.com/embed/yyUFJMm3hv8",
      code: "chazaq",
    },
    {
      title: "Aish Men",
      link: "https://www.torahanytime.com/embed/?v=118850",
      code: "aish",
    },
  ],
  ladies: [
    {
      title: "Project Inspire ladies",
      link: "https://livestream.com/accounts/20631388/events/9112248/player",
      code: "project_inspire",
    },
    {
      title: "Chazaq",
      link: "https://www.youtube.com/embed/yyUFJMm3hv8",
      code: "chazaq",
    },
    {
      title: "Aish",
      link: "https://www.torahanytime.com/embed/?v=118850",
      code: "aish",
    },
  ],
};
