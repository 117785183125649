import { createMuiTheme } from '@material-ui/core';
export const defaultTheme = createMuiTheme({
  palette: {
    text: {
      primary: '#000000',
      secondary: '#00AC99',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      mobile: 480,
      tablet: 768,
      desktop: 1280,
    },
  },
});