import { APIRequest } from "./api";
import { settings, settingsMen, full, agudah } from "./settingsData";
import { tbav, men, ladies } from "./eventsList";
import queryString from "query-string";

let { player_name, version_id: versionId } = queryString.parse(document.location.search);

export const getEvents = () => {
  //WAYNE uncomment
  // switch (player_name) {
  //   case "vayichan_men":
  //     return Promise.resolve(men);
  //   case "vayichan_ladies":
  //     return Promise.resolve(ladies);
  //   default:
  //     return Promise.resolve(tbav);
  // }
  let url = `/data/events_${player_name}.json`;
  if (versionId) {
    url += "?v=" + versionId
  }
  return APIRequest.get(url).then((data) => {
    //console.log("data", data);
    return data.data;
  });
};

export const getSettings = () => {
  // console.log("playerName", player_name);
  //WAYNE uncomment
  // switch (player_name) {
  //   case "vayichan_men":
  //     return Promise.resolve(settingsMen);
  //   case "vayichan_ladies":
  //     return Promise.resolve(settingsladies);
  //   default:
  //     return Promise.resolve(settings);
  // }
  let url = `/data/settings_${player_name}.json`;
  if (versionId) {
    url += "?versionId=" + versionId
  }
  return APIRequest.get(url).then((data) => {
    return data.data;
  });
};

export * from "./api";
