import React, { useState, useRef, useEffect } from "react";
import { Button, Dialog, DialogActions } from "@material-ui/core";
import { useIsMobile, useIsLarge } from "../hooks";
import ReactGA from "react-ga";

function _Schedule({ settings, schedWindowOpen, setSchedWindowOpen }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    ReactGA.event({
      category: "Schedule",
      action: "close",
    });
    setOpen(false);
  };

  const handleOpen = () => {
    ReactGA.event({
      category: "Schedule",
      action: "open",
    });
    setOpen(true);
  };
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    setOpen(schedWindowOpen);
  }, [schedWindowOpen]);

  useEffect(() => {
    if (open) {
      setSchedWindowOpen(true);
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open, setSchedWindowOpen]);

  const isMobile = useIsMobile();
  const isLarge = useIsLarge();
  return (
    <div>
      <Button
        style={{
          height: 32,
          color: "#33B4FF",
          fontSize: isMobile ? 14 : isLarge ? 30 : 18,
          textTransform: "capitalize",
        }}
        onClick={() => handleOpen()}
      >
        Schedule
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        //fullWidth
        fullScreen
      >
        <iframe
          title="schedule"
          src={settings.schedule_url}
          frameBorder="0"
          style={{ height: "100vh", width: "100%" }}
        />
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const Schedule = React.memo(_Schedule);
