import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { defaultTheme } from "./themes";
import { StylesProvider, jssPreset, ThemeProvider } from "@material-ui/styles";
import { create } from "jss";

const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  // see https://material-ui.com/styles/advanced/#css-injection-order
  insertionPoint: document.getElementById("jss-insertion-point"),
});

ReactDOM.render(
  <StylesProvider jss={jss}>
    <ThemeProvider theme={defaultTheme}>
      <App />
    </ThemeProvider>
  </StylesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
