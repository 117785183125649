module.exports = {
  settings: {
    schedule_url: "https://api.torahanytime.com/posts/40738/html",
    bottom_text: "Vayichan Program (Women Only)",
    bottom_link_1: {
      label: "Click Here for the Main Channels",
      url: "https://api.torahanytime.com/posts/40729/html",
      behaviour: "reload_ladies_player",
    },
    overlay: {
      timeUntilFirstDisplay: 3,
      slideImgs: [
        "https://www.bitbean.com/assets/images/ceos-speak/finance-category.png",
        "https://s3.amazonaws.com/player.torahanytime.com/slides/Slide-1-Sample.jpg",
        "https://s3.amazonaws.com/player.torahanytime.com/slides/Slide-2-Sample.jpg",
      ],
      timeToDisplay: 10,
      repeat: true,
      intervalBetweenSlides: 10,
    },
  },
  settingsMen: {
    schedule_url: "https://api.torahanytime.com/posts/40730/html",
    bottom_text: "Vayichan Program",
    bottom_link_1: {
      label: "Click Here for the Women's Channels",
      url: "https://api.torahanytime.com/posts/40729/html",
      behaviour: "reload_ladies_player",
    },
    overlay: {
      timeUntilFirstDisplay: 3,
      slideImgs: [
        "https://torahanytime-files.sfo2.digitaloceanspaces.com/assets/flash/banner/111-Player-Overlay/Player-test-overlay.png",
        "https://s3.amazonaws.com/player.torahanytime.com/slides/Slide-2-Sample.jpg",
      ],
      timeToDisplay: 15,
      repeat: false,
      intervalBetweenSlides: 20,
    },
  },
  settingsladies: {
    schedule_url: "https://api.torahanytime.com/posts/40730/html",
    bottom_text: "Vayichan Program (Women Only)",
    bottom_link_1: {
      label: "Click Here for the Main Channels",
      url: "https://api.torahanytime.com/posts/40729/html",
      behaviour: "reload_ladies_player",
    },
    overlay: {
      slideImgs: [
        "https://s3.amazonaws.com/player.torahanytime.com/slides/Slide-1-Sample.jpg",
        "https://s3.amazonaws.com/player.torahanytime.com/slides/Slide-2-Sample.jpg",
      ],
      timeUntilFirstDisplay: 5,
      timeToDisplay: 40,
      repeat: false,
      intervalBetweenSlides: 120,
    },
  },
  full: {
    logoUrl: 'https://torahanytime-files.sfo2.digitaloceanspaces.com/assets/flash/banner/Agudah2020_Session.png',
    campaignLogo: '',
    pallet: {
      topBar: '#F6C840',
      bottomBar: '#064447',
      donateBtn: '#F6C840'
    },
    logoWidth: {
      lg: 250,
      md: 150,
      sm: 100
    },
    campaignLogoWidth: {
      lg: 125,
      md: 100,
      sm: 85
    },
    videoSelect: {
      color: '#ffffff',
      backgroundColor: '#064447',
      width: {
        lg: 600,
        md: 310,
        sm: 200
      }
    },
    bottom_text: 'Did you miss a session?',
    bottom_link_1: {
      label: 'Go to AgudahConvention.org',
      url: 'https://www.agudahconvention.org',
      behaviour: 'new_tab'
    },
    bottom_link_2: {
      label: '',
      url: '',
      behaviour: 'new_tab'
    },
    bottom_text_style: {
      lg: {
        color: '#FFFFFF',
        fontSize: 25,
        fontWeight: 500
      },
      md: {
        color: '#FFFFFF',
        fontSize: 15,
        fontWeight: 500
      },
      sm: {
        color: '#FFFFFF',
        fontSize: 10,
        fontWeight: 500
      }
    },
    bottomBarStyles: {
      leftBottomRegular: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }
    },
    overlay: {
      slideImgs: [],
      timeUntilFirstDisplay: 5,
      timeToDisplay: 20,
      repeat: true,
      intervalBetweenSlides: 180
    },
    donationNewTab: 'https://www.agudahconvention.org/donate',
    showBitbeanTaBottom: true,
    showBitbeanTaTop: false,
    middleSectionStyle: {
      marginLeft: 20,
    },
    linkStyles: {
      marginRight: 0
    },
    donationBtnTextLine1: {
      txt: 'Donate Now',
      style: {
        color: '#064447',
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'nowrap',
        lg: {
          fontSize: 20
        },
        md: {
          fontSize: 15,
          padding: "10px 20px"
        },
        sm: {
          fontSize: 10
        }
      }
    },
    donationBtnTextLine2: {
      txt: '(suggested $18)',
      style: {
        color: '#064447',
        fontWeight: 500,
        textTransform: 'lowercase',
        whiteSpace: 'nowrap',
        lg: {
          fontSize: 14
        },
        md: {
          fontSize: 12
        },
        sm: {
          fontSize: 8
        }
      }
    }
  },
  agudah: {
    logoUrl: 'https://torahanytime-files.sfo2.digitaloceanspaces.com/assets/flash/banner/Slider_Banners/117-Slider/PI-Convention-2021.png',
    campaignLogo: '',
    pallet: {
      topBar: '#ffa101',
      bottomBar: '#5be8fc',
      donateBtn: '#ffa101'
    },
    logoWidth: {
      lg: 250,
      md: 150,
      sm: 100
    },
    campaignLogoWidth: {
      lg: 125,
      md: 100,
      sm: 85
    },
    videoSelect: {
      color: '#ffffff',
      backgroundColor: '#5be8fc',
      width: {
        lg: 600,
        md: 310,
        sm: 200
      }
    },
    bottom_text: 'For the full convention program go to',
    bottom_link_1: {
      label: 'https://www.projectinspire.com/convention',
      url: 'https://www.projectinspire.com/convention',
      behaviour: 'new_tab',
    },
    bottom_link_2: {
      label: '',
      url: '',
      behaviour: 'new_tab',
    },
    bottom_text_style: {
      lg: {
        color: '#000000',
        fontSize: 25,
        fontWeight: 500
      },
      md: {
        color: '#000000',
        fontSize: 15,
        fontWeight: 500,
        marginBottom: 10
      },
      sm: {
        color: '#000000',
        fontSize: 10,
        fontWeight: 500,
        marginBottom: 8
      }
    },
    bottomBarStyles: {
      leftBottomRegular: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }
    },
    overlay: {
      slideImgs: [],
      timeUntilFirstDisplay: 5,
      timeToDisplay: 20,
      repeat: true,
      intervalBetweenSlides: 180
    },
    donationNewTab: 'https://www.rayze.it/projectinspire/live/landing',
    showBitbeanTaBottom: true,
    showBitbeanTaTop: false,
    middleSectionStyle: {
      marginLeft: 20,
      color: "#000000",
      sm: {
        marginLeft: 0
      }
    },
    linkStyles: {
      marginRight: 0,
      color: "#000000"
    },
    donationBtnTextLine1: {
      txt: 'Donate Now',
      style: {
        color: '#064447',
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'nowrap',
        lg: {
          fontSize: 20
        },
        md: {
          fontSize: 15,
          padding: '10px 20px'
        },
        sm: {
          fontSize: 10
        }
      }
    },
    donationBtnTextLine2: {
      txt: 'CLICK HERE',
      style: {
        color: '#064447',
        fontWeight: 500,
        textTransform: 'lowercase',
        whiteSpace: 'nowrap',
        lg: {
          fontSize: 14
        },
        md: {
          fontSize: 12
        },
        sm: {
          fontSize: 8
        }
      }
    }
  }
};
