import { useTheme, useMediaQuery } from "@material-ui/core";
/**
 * Returns `true` if the screen is sized for mobile. Depends on theme.
 */
export function useIsMobile() {
  const theme = useTheme();
  const { mobile } = theme.breakpoints.values;
  return useMediaQuery(theme.breakpoints.down(mobile));
}

/**
 * Returns `true` if the screen is sized for mobile. Depends on theme.
 */
export function useIsLarge() {
  const theme = useTheme();
  //const { md } = theme.breakpoints.values;
  return useMediaQuery(theme.breakpoints.up("md"));
}
