import axios from "axios";
const {
  /** The base URL for the API. */
  REACT_APP_API_URL,
  //NODE_ENV,
} = process.env;

export const APIRequest = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});
