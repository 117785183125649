import React, { useEffect, useState, useRef } from "react";
import {} from "@material-ui/core";

function _ImageSlider({ settings }) {
  const [moved, setMoved] = useState(false);
  const [activeImageSrc, setActiveImageSrc] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    slideImgs = [],
    timeToDisplay = 60,
    repeat = true,
    timeUntilFirstDisplay = 60,
    intervalBetweenSlides = 60,
  } = settings.overlay;

  const isFirstTime = useRef(true);

  useEffect(() => {
    if (isFirstTime.current) {
      isFirstTime.current = false;
      return;
    }
    let _index = currentIndex;

    const recurring = setInterval(() => {
      const imgSrc = slideImgs[_index];
      setActiveImageSrc(imgSrc);

      //The lines below trigger the animation
      setMoved(true);
      if (_index + 1 < slideImgs.length) {
        _index++;
      } else {
        if (!repeat) {
          clearInterval(recurring);
          return;
        }
        _index = 0;
      }
      //turn slide off after timeToDisplay
      setTimeout(() => {
        setMoved(false);
      }, timeToDisplay * 1000);
    }, (timeToDisplay + intervalBetweenSlides) * 1000);
    return () => {
      clearInterval(recurring);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  useEffect(() => {
    if (!slideImgs.length) {
      return;
    }
    setTimeout(() => {
      const imgSrc = slideImgs[currentIndex];
      setActiveImageSrc(imgSrc);

      //The lines below trigger the animation
      setMoved(true);

      if (currentIndex + 1 < slideImgs.length) {
        setCurrentIndex((curr) => curr + 1);
      } else {
        setCurrentIndex(0);
      }
      //turn slide off after timeToDisplay
      setTimeout(() => {
        setMoved(false);
      }, timeToDisplay * 1000);
    }, timeUntilFirstDisplay * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideImgs, timeToDisplay, timeUntilFirstDisplay]);

  return (
    <div>
      <img
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "auto",
          zIndex: 11,
          position: "absolute",
          bottom: moved ? 87 : "-100%",
          left: 0,
          right: 0,
          transition: "5s ease-in-out",
        }}
        id="banner-img"
        src={activeImageSrc}
        alt=""
      />
    </div>
  );
}

export const ImageSlider = React.memo(_ImageSlider);
