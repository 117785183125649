import React, { useEffect, useState } from "react";
import FullscreenIcon from "@material-ui/icons/Fullscreen";

import "./assets/tbav-player.css";
import { BottomBar, ImageSlider } from "./components";
import { getEvents, getSettings } from "./utils";
import { TopBar } from "./components/TopBar";
import queryString from "query-string";
import ReactGA from "react-ga";

ReactGA.initialize("UA-162203379-1");

let { channel, player_name, version_id: versionId } = queryString.parse(document.location.search);

const parseEvents = (_events) => {
  const _eventsOrder = Array.from(new Set(_events.map((e) => e.code)));
  const _eventsObj = _events.reduce((prev, curr) => {
    prev[curr.code] = curr;
    return prev;
  }, {});
  return { _eventsOrder, _eventsObj };
};

function App() {
  const [settings, setSettings] = useState({ overlay: {} });
  const [events, setEvents] = useState({});
  const [eventsOrder, setEventsOrder] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [schedWindowOpen, setSchedWindowOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState({
    title: "Loading ...",
    link: "",
    code: "",
  });

  const receiveMessage = (e) => {
    if (
      e.origin === "https://api.torahanytime.com" &&
      e.data.action === "change_channel"
    ) {
      if (events[e.data.channel]) {
        setActiveEvent(events[e.data.channel]);
        setSchedWindowOpen(false);
      } else {
        //console.log("in else case")
      }
    }
  };

  const getIframeHeight = () => {
    const height = isFullScreen
      ? window.innerHeight - 80
      : window.innerHeight - 168;
    return height;
  };

  const toggleFullScreen = () => {
    if (isFullScreen) {
      exitFullScreen();
    } else {
      setFullScreen();
    }
  };

  const setFullScreen = () => {
    const elem = document.body;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
    setIsFullScreen(true);
  };

  const exitFullScreen = () => {
    if (!isFullScreen) {
      return;
    }
    try {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } catch (e) {
      console.error("failed to exit full screen");
      console.error(e);
    }

    setIsFullScreen(false);
  };

  useEffect(() => {
    (async function () {
      const [_events, _settings] = await Promise.all([
        getEvents(),
        getSettings(),
      ]);

      const { _eventsOrder, _eventsObj } = parseEvents(_events);
      setSettings(_settings);
      setEventsOrder(_eventsOrder);
      setEvents(_eventsObj);

      if (channel && _eventsObj[channel]) {
        setActiveEvent(_eventsObj[channel]);
      } else {
        setActiveEvent(_eventsObj[_eventsOrder[0]]);
      }
      setInterval(async () => {
        const _settings = await getSettings();
        setSettings(_settings);
      }, 1000 * 60 * 2);
    })();
    return () => {}; //Runs on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeEvent && activeEvent.code) {
      let search = `?player_name=${player_name}&channel=${activeEvent.code}`;

      if (versionId) {
        search += `&version_id=${versionId}`
      }
      window.history.pushState(
        { player_name, channel: activeEvent.code, version_id: versionId },
        activeEvent.title,
        search
      );
      ReactGA.pageview(window.location.pathname + search);
    }
  }, [activeEvent]);

  useEffect(() => {
    window.addEventListener("message", receiveMessage, true);

    return () => {
      window.removeEventListener("message", receiveMessage, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  return (
    <div id="entire-player-wrapper">
      <TopBar
        events={{ ...events }}
        eventsOrder={eventsOrder}
        setActiveEvent={setActiveEvent}
        activeEvent={activeEvent}
        settings={settings}
        schedWindowOpen={schedWindowOpen}
        setSchedWindowOpen={setSchedWindowOpen}
      />
      <div
        className="iframe-wrapper"
        style={{
          overflow: "hidden",
          position: "relative",
          height: "calc(100vh - 168px)",
        }}
      >
        <iframe
          key={isFullScreen + "_" + getIframeHeight()}
          style={{
            border: 0,
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
            zIndex: 10,
          }}
          id="event-player"
          title="event1"
          src={activeEvent.link}
          frameBorder="0"
          scrolling="no"
        ></iframe>
        <div
          id="full-screen-icon"
          style={{
            position: "absolute",
            bottom: -1,
            right: 6,
            zIndex: 999,
          }}
          onClick={toggleFullScreen}
        >
          <FullscreenIcon style={{ color: "#FFF" }} />
        </div>
      </div>
      <BottomBar settings={settings} />
      <ImageSlider settings={settings} />
    </div>
  );
}

export default App;
